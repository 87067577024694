<template>
  <section>
    <vue-headful title="Login - HMD Provider Roster Update Tool" />
    <button v-if="dev" class="button" @click="importData()">
      DEV: import providers
    </button>
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="column is-6 is-offset-3">
            <div class="box">
              <div class="branding">
                <img
                  src="@/assets/HMD-Logo-2019.svg"
                  alt="Healthy Michigan Dental Logo"
                  width="225"
                />
              </div>
              <div class="welcome">
                <b-message type="is-info">
                  If you do not have a Type 2 (organization) NPI, please email
                  <a href="mailto:providerrelations@hmidental.com"
                    >providerrelations@hmidental.com</a
                  >
                  for assistance.
                </b-message>
                <br />
                <h1 class="title has-text-centered">
                  HMD Provider Roster Update Tool
                </h1>
                <p class="has-text-centered">
                  To login, enter the TIN, NPI Type 2, and Zip Code of your
                  office.
                </p>
                <hr />
                <b-field label="TIN:">
                  <b-input
                    v-model="tin"
                    type="number"
                    size="is-medium"
                  ></b-input>
                </b-field>
                <b-field label="NPI Type 2:">
                  <b-input
                    v-model="npi"
                    type="number"
                    size="is-medium"
                  ></b-input>
                </b-field>
                <b-field label="Zip Code (5 digit):">
                  <b-input
                    v-model="zip"
                    type="number"
                    size="is-medium"
                  ></b-input>
                </b-field>
              </div>
              <div class="field is-grouped is-grouped-right">
                <p class="control">
                  <b-button
                    @click.prevent="checkAuth()"
                    :class="{ 'is-loading': loading }"
                    :disabled="loading"
                    class="button is-primary is-medium"
                    type="submit"
                    >Login
                  </b-button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-foot">
        <a
          href="https://hmidental.com"
          target="_blank"
          class="heading has-text-centered"
          style="color: #0865a3"
        >
          &copy; 2022 Healthy Michigan Dental
        </a>
      </div>
    </section>
  </section>
</template>

<script>
import { db } from "@/main";
import providers from "@/providers.json";

export default {
  data: () => {
    return {
      loading: false,
      tin: undefined,
      npi: undefined,
      zip: undefined,
      dev: false,
    };
  },
  methods: {
    importData() {
      console.log(providers[0]["NPI 2"]);
      providers.forEach((provider) => {
        db.collection("provider-data")
          .doc()
          .set(provider)
          .then(() => {
            console.log(`Document successfully written! ${provider["NPI 2"]}`);
          });
      });
    },
    checkAuth() {
      this.loading = true;
      db.collection("provider-data")
        .where("TIN", "==", Number(this.tin))
        .where("NPI 2", "==", Number(this.npi))
        .where("Zip Code", "==", Number(this.zip))
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            this.$buefy.snackbar.open({
              duration: 5000,
              message: "Invalid credentials",
              type: "is-danger",
              position: "is-top-right",
            });
            this.loading = false;
          } else {
            let results = [];
            querySnapshot.forEach((doc) => {
              results.push(doc.data());
            });
            results.sort((a, b) =>
              a["Last Name"].localeCompare(b["Last Name"])
            );
            this.handlePostLogin(results);
          }
        });
    },
    handlePostLogin(results) {
      const officeInfo = {
        tin: this.tin,
        npi: this.npi,
        zip: this.zip,
      };
      this.$store.commit("setOfficeMeta", officeInfo);
      this.$store.commit("setOfficeResults", results);
      this.loading = false;
      this.$router.push("/recred");
    },
  },
};
</script>

<style lang="scss" scoped>
$light-100: #ffffff;
$light-200: #f7f9fc;
$light-300: #edf1f7; // APP BG COLOR
$light-400: #e4e9f2;
$light-500: #c5cee0;
$light-600: #8f9bb3;
$light-700: #2e3a59;
$light-800: #222b45;
$light-900: #1a2138;
$light-950: #151a30;
$text-shadow: 0px 2px 4px rgba(2, 1, 24, 0.466);
$shadow-small: 0px 2px 2px rgba(2, 1, 24, 0.25);
$shadow-medium: 0px 4px 4px rgba(2, 1, 24, 0.25);
$shadow-large: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
$shadow-large-error: 0 0.5rem 1rem 0 rgba(182, 5, 5, 0.4);
$navy: #00024a;
$maroon: #80276c;
$storm: #d9e2ee;
$blue: #0865a3;
$text-light: rgb(237, 237, 237);
$text-light-adaptive: rgba(237, 237, 237, 0.9);
$text-dark: $light-900;
$text-heading: #375775;
$text-muted: #4f566b;
.hero {
  background: rgb(247, 249, 252);
  background: radial-gradient(
    circle,
    rgba(247, 249, 252, 1) 0%,
    $light-500 100%
  ) !important;
  // .hero-body {
  //   min-height: 100vh !important;
  // }
}

.box {
  box-shadow: $shadow-large;
  padding: 2rem;
  border-top: 6px solid $blue;
  .branding {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    text-align: center;
    img {
      width: 330px;
    }
  }
  .welcome {
    margin-bottom: 4rem;
    .title {
      font-size: 1.75rem;
    }
    .subtitle {
      color: $light-700;
      padding-top: 1rem;
    }
  }
  a {
    text-decoration: underline;
  }
}

// input {
//   background: white !important;
//   border-color: transparent;
//   &:focus {
//     box-shadow: 0 1px 3px 0 #cfd7df;
//   }
// }
.maintenance {
  background: #d4f057;
  color: $light-800;
  &.hero {
    .title,
    .subtitle {
      color: $text-dark;
      a {
        color: $navy;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .hero-body {
    padding: 0.15rem;
  }
  .hero-footer {
    margin-top: -2rem;
  }
  .box {
    margin-top: 0px;
    padding: 01rem;
  }
  .branding {
    padding-top: 1rem;
    padding-bottom: 1rem;
    img {
      width: 175px;
    }
  }
  .welcome {
    margin-bottom: 2rem;
    .title {
      font-size: 1.05rem;
    }
    .subtitle {
      font-size: 0.9rem;
      padding-top: 0.4rem;
    }
  }
}
.pulse-card {
  padding: 0 !important;
  margin-top: 3rem;
  border: none !important;
  height: auto !important;
  margin-bottom: 3rem;
  .head {
    background: $navy;
    color: white;
    text-align: center;
    .title {
      color: white;
    }
  }
  .body {
    padding: 1.5rem;
    .subtitle {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
  .help {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    color: $text-dark;
    strong {
      color: $navy;
    }
  }
  .title {
    font-weight: 300;
    &.success-title {
      font-size: 1.7rem !important;
      color: white;
    }
  }
  .subtitle {
    padding-bottom: 0.5rem;
    padding-top: 0;
    font-weight: 300;
    font-size: 1rem;
    color: $text-dark;
  }
  .data-container {
    p {
      padding-bottom: 1.3rem;
    }
    .data {
      text-align: right;
      .data-data {
        font-weight: 600;
      }
    }
  }
}
.incorrectCredentials {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
  box-shadow: $shadow-large-error;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(4px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-6px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(8px, 0, 0);
  }
}

@media only screen and (max-width: 600px) {
  .maintenance {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
</style>
